import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { EGroupPartition } from 'api/groups/types';

import { groupsListWidgetSettingsParams as settingsParams } from 'settings/groups-list-widget';

import { WixGroupsController } from '../../controller';

const createController: CreateControllerFn = async (params) => {
  const { flowAPI } = params;

  const controller = new WixGroupsController(params);

  controller.handleTransition = handleTransition;

  const { wixCodeApi } = flowAPI.controllerConfig;
  wixCodeApi.user.onLogin(handleTransition);

  return controller;

  async function handleTransition() {
    await Promise.all([
      controller.vm.groups$.query({
        namespace: EGroupPartition.ALL,
        partition: EGroupPartition.ALL,
        limit: flowAPI.settings.get(settingsParams.numberGroups),
        sort: flowAPI.settings.get(settingsParams.sortBy),
      }),
      controller.vm.application$.fetchUserProfile(),
    ]);

    return {};
  }
};

export default createController;
